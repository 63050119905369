import React, { useEffect, useState } from 'react'
import { blurBodyWrapper } from '../../utilities/common'
import EventManager from '../../utilities/eventManager'
import ContactModal from '../contact_modal/contact_modal'
import HelpContactModal from '../contact_modal/helpContact_modal'

export default function VolunteerCTA(props) {
    const [showModal, setShowModal] = useState(false)
    const volunteerModalData = {
        title: 'Become a Volunteer',
        message: 'I would like to be a Volunteer',
    }

    useEffect(() => {}, [])

    return (
        <div className="volunteer-cta">
            <HelpContactModal
                open={showModal}
                setShowModal={setShowModal}
                isOptionsVal={false}
            />

            <section id="sofa-collection">
                <div>
                    <div className="sofa-collection collectionbg2">
                        <div className="container">
                            <div className="sofa-collection-txt">
                                <h2>Become a Volunteer</h2>
                                <p>
                                    There is no greater gift than your time.
                                    Volunteering is a rewarding endeavor that
                                    provides insight and personal enrichment.
                                    Side benefits include developing
                                    professional connections, gaining experience
                                    and making new friends. If you are young at
                                    heart, interested in developing the Youth
                                    and interested in making a difference to
                                    children who need you support, then you are 
                                    just the right person we have been looking for. 
                                </p>

                                <button
                                    id="voluteer-button"
                                    onClick={(e) => {
                                        setShowModal(true)
                                        blurBodyWrapper('.38')
                                        EventManager.publish(
                                            EventManager.OPEN_MODAL,
                                            volunteerModalData
                                        )
                                    }}
                                >
                                    Get In Contact
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
