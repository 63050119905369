import React, { useState } from 'react'
import {
    getAddUsInYourWillText,
    getDonateToUsInKindText,
    getHomeworkText,
    getTransportText,
    getMatrickerDanceText,
    getBirthdayText,
    getHostAChildText,
    getBefriendAChildText,
    getFundraiseText,
    getOfficeText,
    tabNames,
} from './tabs_data'

import { blurBodyWrapper } from '../../utilities/common'
import EventManager from '../../utilities/eventManager'
import HelpContactModal from '../contact_modal/helpContact_modal'
import gift from '../../assets/images/kids/gifts.jpg'
import homework from '../../assets/images/kids/homework.jpg'
import transport from '../../assets/images/kids/transport.jpg'
import matricdance from '../../assets/images/kids/matricdance.jpg'
import birthday from '../../assets/images/kids/birthday.jpg'
import hostchild from '../../assets/images/kids/hostchild.jpg'
import befriendchild from '../../assets/images/kids/befriendchild.jpg'
import fundraiser from '../../assets/images/kids/fundraiser.jpg'
import officehelp from '../../assets/images/kids/officehelp.jpg'

export default function Tabs() {
    const tabs = [
        {
            name: '',
            index: 0,
            heading: 'Donate gifts in kind',
            text: getDonateToUsInKindText(),
            image: gift,
        },
        {
            name: '',
            index: 1,
            heading: 'Help with transport',
            text: getTransportText(),
            image: transport,
        },
        {
            name: '',
            index: 2,
            heading: 'Help with homework',
            text: getHomeworkText(),
            image: homework,
        },
        {
            name: '',
            index: 3,
            heading: 'Make Matric Dance magic',
            text: getMatrickerDanceText(),
            image: matricdance,
        },
        {
            name: '',
            index: 4,
            heading: 'Sponsor a birthday party',
            text: getBirthdayText(),
            image: birthday,
        },
        {
            name: '',
            index: 5,
            heading: 'Host a child',
            text: getHostAChildText(),
            image: hostchild,
        },
        {
            name: '',
            index: 6,
            heading: 'Befriend a child',
            text: getBefriendAChildText(),
            image: befriendchild,
        },
        {
            name: '',
            index: 7,
            heading: 'Fundraise for us',
            text: getFundraiseText(),
            image: fundraiser,
        },
        {
            name: '',
            index: 8,
            heading: 'Help out in our office',
            text: getOfficeText(),
            image: officehelp,
        },
        {
            name: tabNames.WILL,
            index: 9,
            heading: 'Remember us in your will',
            text: getAddUsInYourWillText(),
            // image: pic2
        },
    ]

    const [showModal, setShowModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState(tabs[0])

    const volunteerModalData = {
        title: 'Become a Volunteer',
        message: 'I would like to be a Volunteer',
    }

    const [FormPlaceHolder, setFormPlaceHolder] = useState({
        title: 'Become a Volunteer',
        message: 'I would like to be a Volunteer',
    })

    const changeFormMessage = () => {
        tabs.map((tab) => {
            if (tab.index === selectedTab) {
                setFormPlaceHolder({
                    title: 'Become a Volunteer',
                    message: tab.heading,
                })
            }
        })
    }

    return (
        <div className="tabs">
            <HelpContactModal open={showModal} setShowModal={setShowModal} />
            <h3 className="tabs_heading">How you can help us</h3>

            <div className="row">
                <div className="tabs_wrapper">
                    {tabs.map((tab, index) => {
                        return (
                            <span
                                key={index}
                                className={
                                    (tab.index === selectedTab.index
                                        ? 'active'
                                        : '') + ' help-tab'
                                }
                                onClick={() => setSelectedTab(tab)}
                            >
                                {tab.heading}
                            </span>
                        )
                    })}
                </div>

                <div className="tabs_content_wrapper">
                    <div className="row">
                        {/* The will text requires a different layout */}
                        {/* {selectedTab.image === undefined ? getAddUsInYourWillText() : <> */}
                        {selectedTab.image ? (
                            <div className="col-sm-6 image-container">
                                <img src={selectedTab.image} alt="pic" />
                            </div>
                        ) : (
                            <div className="col-sm-6" style={{ width: '100%' }}>
                                {/* then just show the text and no image*/}
                            </div>
                        )}
                        <div className="col-sm-6">
                            <div className="tabs_content">
                                {selectedTab.text}
                            </div>
                            <div className="tabs_content-2">
                                <button
                                    className="will-button"
                                    onClick={() => {
                                        setShowModal(true)
                                        blurBodyWrapper('.38')

                                        EventManager.publish(
                                            EventManager.OPEN_MODAL,
                                            FormPlaceHolder.message
                                        )
                                    }}
                                >
                                    Apply Now
                                </button>
                            </div>
                        </div>
                        {/* </>} */}
                    </div>
                </div>
            </div>
        </div>
    )
}
