import async from '../hoc/async_component'
import ContactUsPage from '../pages/contact_us_page/contact_us_page'
import HomePage from '../pages/home_page/home_page'
import { DonateApp } from '../pages/donate/donate'


const Home = async(() => import('../pages/home_page/home_page'))
const AboutUs = async(() => import('../pages/about_us_page/about_us_page'))
const News = async(() => import('../pages/news_page/news_page'))
const viewPostDetails = async(() => import('../pages/news_page/viewPost'))
const No_Page = async(() => import('../pages/404_page/noPage'))
const SponserChild = async(() => import('../pages/footer_pages/sponserChild'))
const Community = async(() => import('../pages/footer_pages/community'))
const Wishlist = async(() => import('../pages/footer_pages/wishlist'))

const paths = {
    Home: '/',
    AboutUs: '/aboutus',
    News: '/news',
    ContactUs: '/contactus',
    Donate: '/donate',
    Post: '/post',
    NoPage: '/*',
    community: '/community',
    wishlist: '/wishlist',
    sponserAchild: '/sponserAchild',

}

const routes = [
    {
        path: paths.Home,
        component: Home,
        isProtected: false,
        exact: true,
    },
    {
        path: paths.AboutUs,
        component: AboutUs,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.News,
        component: News,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.Post,
        component: viewPostDetails,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.ContactUs,
        // TODO: create contact us page
        component: ContactUsPage,
        isProtected: false,
        exact: false,
    },
    // {
    //     path: paths.qrcodeGenerator,
    //     component: DonateQRCode,
    //     isProtected: false,
    //     exact: false,
    // },
    {
        path: paths.NoPage,
        component: No_Page,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.sponserAchild,
        component: SponserChild,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.community,
        component: Community,
        isProtected: false,
        exact: false,
    },
    {
        path: paths.wishlist,
        component: Wishlist,
        isProtected: false,
        exact: false,
    }
]

export { routes, paths }
