import React from 'react'

import img1 from '../../assets/images/kids/pic5.jpg'
import img2 from '../../assets/images/kids/pic4.jpg'
import img3 from '../../assets/images/kids/pic3.jpg'

function HeaderCarousel() {

    const scrolldown = (e) => {
        window.scrollTo({
            top: 800,
            behavior: 'smooth',
          });
    }

    return (
        <div className="header-carousel-wrapper">
            <div className="header-text-container">
                <h5> St Mary's childrens Home</h5>
                <p> steps to a brighter future</p>

                <button onClick={(e) => scrolldown(e)}> Learn More</button>
            </div>
            <div
                id="header-carousel"
                className="carousel slide carousel-fade"
                data-ride="carousel"
            >
                <ol className="carousel-indicators">
                    <li
                        data-target="#header-carousel"
                        data-slide-to="0"
                        className="active"
                    >
                        <span className="small-circle"></span>
                    </li>
                    <li data-target="#header-carousel" data-slide-to="1">
                        <span className="small-circle"></span>
                    </li>
                    <li data-target="#header-carousel" data-slide-to="2">
                        <span className="small-circle"></span>
                    </li>
                </ol>

                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <div className="single-slide-item slide1">
                            <div className="welcome-hero-content">
                                <div className="single-welcome-hero">
                                    <div className="welcome-hero-img">
                                        <img src={img1} alt="slider image 1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="single-slide-item slide2">
                            <div className="welcome-hero-content">
                                <div className="single-welcome-hero">
                                    <div className="welcome-hero-img">
                                        <img src={img2} alt="slider image 2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="single-slide-item slide3">
                            <div className="welcome-hero-content">
                                <div className="single-welcome-hero">
                                    <div className="welcome-hero-img">
                                        <img src={img3} alt="slider image 3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderCarousel
