import React, { useEffect } from 'react'
import { useRef } from 'react'
import img3 from '../../assets/images/kids/pic11.jpg'
import HeaderCarousel from '../../components/header_carousel/header_carousel'
import Tabs from '../../components/tabs/tabs'
import VolunteerCTA from '../../components/volunteer_cta/volunteer_cta'
import Donate from '../../components/donate_Form/donation_form'
import ConsentBanner from '../../components/consent_banner/consent_banner'
import { consentStrng } from '../../components/consent_banner/consent_banner'

export default function HomePage() {

    const ref = useRef(null)



    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const qrParam = searchParams.get('qr');

        if (qrParam === 'true') {
            ref.current.scrollIntoView()
            ref.current.displayDonationForm()
        } else {
            // go to to of the page at every rerender
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [])



    return (
        <React.Fragment>
            <header id="home" className="welcome-hero">
                <HeaderCarousel />

                {sessionStorage.getItem('Consent') ? '' : <ConsentBanner />}
                <div className="home_description_text">
                    <div className="col-lg-5">
                        <h1>We are a beacon of hope</h1>
                        <p>
                            St. Mary's Children's Home stands as a beacon of
                            hope and stability, illuminating the lives of
                            countless children for over a century. With a legacy
                            rooted in compassion and dedication, this esteemed
                            institution has earned the respect and admiration of
                            communities far and wide. For more than a 100 years,
                            St. Mary's Children's Home has been a sanctuary for
                            those in need, offering not just shelter, but also
                            nurturing care and unwavering support. Believing
                            firmly in the mantra of "steps to a brighter
                            future," every child who enters its doors is greeted
                            with love, encouragement, and the promise of a
                            better tomorrow. As an integral part of the
                            community, St. Mary's Children's Home continues to
                            uphold its legacy, shaping young lives and
                            instilling within them the belief that no dream is
                            too big and no obstacle insurmountable.
                        </p>
                    </div>
                    <div className="col-lg-7">
                        <div className="img-container">
                            <div className="img-container-inner">
                                <img src={img3} alt="pic" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Tabs />
            <Donate ref={ref} />
            <VolunteerCTA />
        </React.Fragment>
    )
}
