import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { blurBodyWrapper } from '../../utilities/common'
import EventManager from '../../utilities/eventManager'
import { logInfo } from '../../utilities/log'
import HelpContactForm from '../contact_form/helpContact_form'


export default function HelpContactModal({ open, setShowModal, isOptionsVal}) {
    const [modalTitle, setModalTitle] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        document
            .querySelector('#contact-modal')
            .addEventListener('click', (event) => {
                event.stopPropagation()

                // only hide the modal if a section outside the form was clicked
                if (event.srcElement.className === 'contact-modal') {
                    logInfo('closing modal')
                    closeModal()
                }
            })
        subscribeToModalEvent()
        // cleanup
        // return () => {}
    }, [modalTitle, message])
    /**
     * a method used subscribe to modal open event
     *
     */
    const subscribeToModalEvent = () => {
        EventManager.subscribe(
            EventManager.OPEN_MODAL,
            'ContactModal',
            (data) => {
                logInfo('recieved data in modal :', data)

                if (data && data.title) {
                    setModalTitle(data.title)
                }

                if (data && data.message) {
                    setMessage(data.message)
                }
            }
        )
    }
    /**
     * A method used to close the contact form modal.
     *
     */
    const closeModal = () => {
        setShowModal(false)
        blurBodyWrapper('0')
    }

    if (!open) return null

    return (
        <React.Fragment>
            {createPortal(
                <div className="contact-modal">
                    

                    <div className="contact-modal-inner">
                        <h3>{modalTitle}</h3>
                        <small>
                            send us your contact details and we will surely get
                            back to you.
                        </small>
                        <HelpContactForm
                            closeModal={closeModal}
                            message={message}
                            isOptions={isOptionsVal}
                        />
                    </div>
                </div>,
                document.querySelector('#contact-modal')
            )}
        </React.Fragment>
    )
}
