export const currencyNames = {
    ADA: 'Cardano',
    AED: 'United Arab Emirates Dirham',
    AFN: 'Afghan Afghani',
    ALL: 'Albanian Lek',
    AMD: 'Armenian Dram',
    ANG: 'Netherlands Antillean Guilder',
    AOA: 'Angolan Kwanza',
    ARB: 'Arabian Monetary Fund',
    ARS: 'Argentine Peso',
    AUD: 'Australian Dollar',
    AVAX: 'Avalanche',
    AWG: 'Aruban Florin',
    AZN: 'Azerbaijani Manat',
    BAM: 'Bosnia And Herzegovina Convertible Mark',
    BBD: 'Barbadian Dollar',
    BDT: 'Bangladeshi Taka',
    BGN: 'Bulgarian Lev',
    BHD: 'Bahraini Dinar',
    BIF: 'Burundian Franc',
    BMD: 'Bermudian Dollar',
    BNB: 'Binance Coin',
    BND: 'Brunei Dollar',
    BOB: 'Bolivian Boliviano',
    BRL: 'Brazilian Real',
    BSD: 'Bahamian Dollar',
    BTC: 'Bitcoin',
    BTN: 'Bhutanese Ngultrum',
    BUSD: 'Binance USD',
    BWP: 'Botswana Pula',
    BYN: 'Belarusian Ruble',
    BYR: 'Belarusian Ruble (pre-2016)',
    BZD: 'Belize Dollar',
    CAD: 'Canadian Dollar',
    CDF: 'Congolese Franc',
    CHF: 'Swiss Franc',
    CLF: 'Chilean Unit of Account (UF)',
    CLP: 'Chilean Peso',
    CNY: 'Chinese Yuan',
    COP: 'Colombian Peso',
    CRC: 'Costa Rican Colón',
    CUC: 'Cuban Convertible Peso',
    CUP: 'Cuban Peso',
    CVE: 'Cape Verdean Escudo',
    CZK: 'Czech Republic Koruna',
    DAI: 'Dai',
    DJF: 'Djiboutian Franc',
    DKK: 'Danish Krone',
    DOP: 'Dominican Peso',
    DOT: 'Polkadot',
    DZD: 'Algerian Dinar',
    EGP: 'Egyptian Pound',
    ERN: 'Eritrean Nakfa',
    ETB: 'Ethiopian Birr',
    ETH: 'Ethereum',
    EUR: 'Euro',
    FJD: 'Fijian Dollar',
    FKP: 'Falkland Islands Pound',
    GBP: 'British Pound Sterling',
    GEL: 'Georgian Lari',
    GGP: 'Guernsey Pound',
    GHS: 'Ghanaian Cedi',
    GIP: 'Gibraltar Pound',
    GMD: 'Gambian Dalasi',
    GNF: 'Guinean Franc',
    GTQ: 'Guatemalan Quetzal',
    GYD: 'Guyanaese Dollar',
    HKD: 'Hong Kong Dollar',
    HNL: 'Honduran Lempira',
    HRK: 'Croatian Kuna',
    HTG: 'Haitian Gourde',
    HUF: 'Hungarian Forint',
    IDR: 'Indonesian Rupiah',
    ILS: 'Israeli New Shekel',
    IMP: 'Isle of Man Pound',
    INR: 'Indian Rupee',
    IQD: 'Iraqi Dinar',
    IRR: 'Iranian Rial',
    ISK: 'Icelandic Króna',
    JEP: 'Jersey Pound',
    JMD: 'Jamaican Dollar',
    JOD: 'Jordanian Dinar',
    JPY: 'Japanese Yen',
    KES: 'Kenyan Shilling',
    KGS: 'Kyrgyzstani Som',
    KHR: 'Cambodian Riel',
    KMF: 'Comorian Franc',
    KPW: 'North Korean Won',
    KRW: 'South Korean Won',
    KWD: 'Kuwaiti Dinar',
    KYD: 'Cayman Islands Dollar',
    KZT: 'Kazakhstani Tenge',
    LAK: 'Lao Kip',
    LBP: 'Lebanese Pound',
    LKR: 'Sri Lankan Rupee',
    LRD: 'Liberian Dollar',
    LSL: 'Lesotho Loti',
    LTC: 'Litecoin',
    LTL: 'Lithuanian Litas',
    LVL: 'Latvian Lats',
    LYD: 'Libyan Dinar',
    MAD: 'Moroccan Dirham',
    MATIC: 'Polygon',
    MDL: 'Moldovan Leu',
    MGA: 'Malagasy Ariary',
    MKD: 'Macedonian Denar',
    MMK: 'Myanmar Kyat',
    MNT: 'Mongolian Tugrik',
    MOP: 'Macanese Pataca',
    MRO: 'Mauritanian Ouguiya',
    MUR: 'Mauritian Rupee',
    MVR: 'Maldivian Rufiyaa',
    MWK: 'Malawian Kwacha',
    MXN: 'Mexican Peso',
    MYR: 'Malaysian Ringgit',
    MZN: 'Mozambican Metical',
    NAD: 'Namibian Dollar',
    NGN: 'Nigerian Naira',
    NIO: 'Nicaraguan Córdoba',
    NOK: 'Norwegian Krone',
    NPR: 'Nepalese Rupee',
    NZD: 'New Zealand Dollar',
    OMR: 'Omani Rial',
    OP: 'Opacity',
    PAB: 'Panamanian Balboa',
    PEN: 'Peruvian Nuevo Sol',
    PGK: 'Papua New Guinean Kina',
    PHP: 'Philippine Peso',
    PKR: 'Pakistani Rupee',
    PLN: 'Polish Złoty',
    PYG: 'Paraguayan Guarani',
    QAR: 'Qatari Rial',
    RON: 'Romanian Leu',
    RSD: 'Serbian Dinar',
    RUB: 'Russian Ruble',
    RWF: 'Rwandan Franc',
    SAR: 'Saudi Riyal',
    SBD: 'Solomon Islands Dollar',
    SCR: 'Seychellois Rupee',
    SDG: 'Sudanese Pound',
    SEK: 'Swedish Krona',
    SGD: 'Singapore Dollar',
    SHP: 'Saint Helena Pound',
    SLL: 'Sierra Leonean Leone',
    SOL: 'Solana',
    SOS: 'Somali Shilling',
    SRD: 'Surinamese Dollar',
    STD: 'São Tomé and Príncipe Dobra',
    SVC: 'Salvadoran Colón',
    SYP: 'Syrian Pound',
    SZL: 'Swazi Lilangeni',
    THB: 'Thai Baht',
    TJS: 'Tajikistani Somoni',
    TMT: 'Turkmenistan Manat',
    TND: 'Tunisian Dinar',
    TOP: "Tongan Pa'anga",
    TRY: 'Turkish Lira',
    TTD: 'Trinidad and Tobago Dollar',
    TWD: 'New Taiwan Dollar',
    TZS: 'Tanzanian Shilling',
    UAH: 'Ukrainian Hryvnia',
    UGX: 'Ugandan Shilling',
    USD: 'United States Dollar',
    USDC: 'USD Coin',
    USDT: 'Tether',
    UYU: 'Uruguayan Peso',
    UZS: 'Uzbekistan Som',
    VEF: 'Venezuelan Bolívar',
    VND: 'Vietnamese Đồng',
    VUV: 'Vanuatu Vatu',
    WST: 'Samoan Tala',
    XAF: 'Central African CFA Franc',
    XAG: 'Silver Ounce',
    XAU: 'Gold Ounce',
    XCD: 'East Caribbean Dollar',
    XDR: 'Special Drawing Rights',
    XOF: 'West African CFA Franc',
    XPD: 'Palladium Ounce',
    XPF: 'CFP Franc',
    XPT: 'Platinum Ounce',
    XRP: 'Ripple',
    YER: 'Yemeni Rial',
    ZAR: 'South African Rand',
    ZMK: 'Zambian Kwacha (pre-2013)',
    ZMW: 'Zambian Kwacha',
    ZWL: 'Zimbabwean Dollar',
}

//TODO: UPDATE THESE VALUES THERE ARE NEW COUNTRY CODES BEING SENT
export const currencies = {
    ADA: {
        name: 'Cardano',
        countryCode: 'AD',
    },
    AED: {
        name: 'United Arab Emirates Dirham',
        countryCode: 'AE',
    },
    AFN: {
        name: 'Afghan Afghani',
        countryCode: 'AF',
    },
    ALL: {
        name: 'Albanian Lek',
        countryCode: 'AL',
    },
    AMD: {
        name: 'Armenian Dram',
        countryCode: 'AM',
    },
    ANG: {
        name: 'Netherlands Antillean Guilder',
        countryCode: 'AN',
    },
    AOA: {
        name: 'Angolan Kwanza',
        countryCode: 'AO',
    },
    ARB: {
        name: 'Arabian Monetary Fund',
        countryCode: 'AE',
    },
    ARS: {
        name: 'Argentine Peso',
        countryCode: 'AR',
    },
    AUD: {
        name: 'Australian Dollar',
        countryCode: 'AU',
    },
    AVAX: {
        name: 'Avalanche',
        countryCode: 'AW',
    },
    AWG: {
        name: 'Aruban Florin',
        countryCode: 'AW',
    },
    AZN: {
        name: 'Azerbaijani Manat',
        countryCode: 'AZ',
    },
    BAM: {
        name: 'Bosnia And Herzegovina Convertible Mark',
        countryCode: 'BA',
    },
    BBD: {
        name: 'Barbadian Dollar',
        countryCode: 'BB',
    },
    BDT: {
        name: 'Bangladeshi Taka',
        countryCode: 'BD',
    },
    BGN: {
        name: 'Bulgarian Lev',
        countryCode: 'BG',
    },
    BHD: {
        name: 'Bahraini Dinar',
        countryCode: 'BH',
    },
    BIF: {
        name: 'Burundian Franc',
        countryCode: 'BI',
    },
    BMD: {
        name: 'Bermudian Dollar',
        countryCode: 'BM',
    },
    BNB: {
        name: 'Binance Coin',
        countryCode: 'US',
    },
    BND: {
        name: 'Brunei Dollar',
        countryCode: 'BN',
    },
    BOB: {
        name: 'Bolivian Boliviano',
        countryCode: 'BO',
    },
    BRL: {
        name: 'Brazilian Real',
        countryCode: 'BR',
    },
    BSD: {
        name: 'Bahamian Dollar',
        countryCode: 'BS',
    },
    BTC: {
        name: 'Bitcoin',
        countryCode: 'ZZ',
    },
    BTN: {
        name: 'Bhutanese Ngultrum',
        countryCode: 'BT',
    },
    BUSD: {
        name: 'Binance USD',
        countryCode: 'US',
    },
    BWP: {
        name: 'Botswana Pula',
        countryCode: 'BW',
    },
    BYN: {
        name: 'Belarusian Ruble',
        countryCode: 'BY',
    },
    BYR: {
        name: 'Belarusian Ruble (pre-2016)',
        countryCode: 'BY',
    },
    BZD: {
        name: 'Belize Dollar',
        countryCode: 'BZ',
    },
    CAD: {
        name: 'Canadian Dollar',
        countryCode: 'CA',
    },
    CDF: {
        name: 'Congolese Franc',
        countryCode: 'CD',
    },
    CHF: {
        name: 'Swiss Franc',
        countryCode: 'CH',
    },
    CLF: {
        name: 'Chilean Unit of Account (UF)',
        countryCode: 'CL',
    },
    CLP: {
        name: 'Chilean Peso',
        countryCode: 'CL',
    },
    CNY: {
        name: 'Chinese Yuan',
        countryCode: 'CN',
    },
    COP: {
        name: 'Colombian Peso',
        countryCode: 'CO',
    },
    CRC: {
        name: 'Costa Rican Colón',
        countryCode: 'CR',
    },
    CUC: {
        name: 'Cuban Convertible Peso',
        countryCode: 'CU',
    },
    CUP: {
        name: 'Cuban Peso',
        countryCode: 'CU',
    },
    CVE: {
        name: 'Cape Verdean Escudo',
        countryCode: 'CV',
    },
    CZK: {
        name: 'Czech Republic Koruna',
        countryCode: 'CZ',
    },
    DAI: {
        name: 'Dai',
        countryCode: 'DK',
    },
    DJF: {
        name: 'Djiboutian Franc',
        countryCode: 'DJ',
    },
    DKK: {
        name: 'Danish Krone',
        countryCode: 'DK',
    },
    DOP: {
        name: 'Dominican Peso',
        countryCode: 'DO',
    },
    DOT: {
        name: 'Polkadot',
        countryCode: 'US',
    },
    DZD: {
        name: 'Algerian Dinar',
        countryCode: 'DZ',
    },
    EGP: {
        name: 'Egyptian Pound',
        countryCode: 'EG',
    },
    ERN: {
        name: 'Eritrean Nakfa',
        countryCode: 'ER',
    },
    ETB: {
        name: 'Ethiopian Birr',
        countryCode: 'ET',
    },
    ETH: {
        name: 'Ethereum',
        countryCode: 'US',
    },
    EUR: {
        name: 'Euro',
        countryCode: 'EU',
    },
    FJD: {
        name: 'Fijian Dollar',
        countryCode: 'FJ',
    },
    FKP: {
        name: 'Falkland Islands Pound',
        countryCode: 'FK',
    },
    GBP: {
        name: 'British Pound Sterling',
        countryCode: 'GB',
    },
    GEL: {
        name: 'Georgian Lari',
        countryCode: 'GE',
    },
    GGP: {
        name: 'Guernsey Pound',
        countryCode: 'GG',
    },
    GHS: {
        name: 'Ghanaian Cedi',
        countryCode: 'GH',
    },
    GIP: {
        name: 'Gibraltar Pound',
        countryCode: 'GI',
    },
    GMD: {
        name: 'Gambian Dalasi',
        countryCode: 'GM',
    },
    GNF: {
        name: 'Guinean Franc',
        countryCode: 'GN',
    },
    GTQ: {
        name: 'Guatemalan Quetzal',
        countryCode: 'GT',
    },
    GYD: {
        name: 'Guyanaese Dollar',
        countryCode: 'GY',
    },
    HKD: {
        name: 'Hong Kong Dollar',
        countryCode: 'HK',
    },
    HNL: {
        name: 'Honduran Lempira',
        countryCode: 'HN',
    },
    HRK: {
        name: 'Croatian Kuna',
        countryCode: 'HR',
    },
    HTG: {
        name: 'Haitian Gourde',
        countryCode: 'HT',
    },
    HUF: {
        name: 'Hungarian Forint',
        countryCode: 'HU',
    },
    IDR: {
        name: 'Indonesian Rupiah',
        countryCode: 'ID',
    },
    ILS: {
        name: 'Israeli New Shekel',
        countryCode: 'IL',
    },
    IMP: {
        name: 'Isle of Man Pound',
        countryCode: 'IM',
    },
    INR: {
        name: 'Indian Rupee',
        countryCode: 'IN',
    },
    IQD: {
        name: 'Iraqi Dinar',
        countryCode: 'IQ',
    },
    IRR: {
        name: 'Iranian Rial',
        countryCode: 'IR',
    },
    ISK: {
        name: 'Icelandic Króna',
        countryCode: 'IS',
    },
    JEP: {
        name: 'Jersey Pound',
        countryCode: 'JE',
    },
    JMD: {
        name: 'Jamaican Dollar',
        countryCode: 'JM',
    },
    JOD: {
        name: 'Jordanian Dinar',
        countryCode: 'JO',
    },
    JPY: {
        name: 'Japanese Yen',
        countryCode: 'JP',
    },
    KES: {
        name: 'Kenyan Shilling',
        countryCode: 'KE',
    },
    KGS: {
        name: 'Kyrgyzstani Som',
        countryCode: 'KG',
    },
    KHR: {
        name: 'Cambodian Riel',
        countryCode: 'KH',
    },
    KMF: {
        name: 'Comorian Franc',
        countryCode: 'KM',
    },
    KPW: {
        name: 'North Korean Won',
        countryCode: 'KP',
    },
    KRW: {
        name: 'South Korean Won',
        countryCode: 'KR',
    },
    KWD: {
        name: 'Kuwaiti Dinar',
        countryCode: 'KW',
    },
    KYD: {
        name: 'Cayman Islands Dollar',
        countryCode: 'KY',
    },
    KZT: {
        name: 'Kazakhstani Tenge',
        countryCode: 'KZ',
    },
    LAK: {
        name: 'Lao Kip',
        countryCode: 'LA',
    },
    LBP: {
        name: 'Lebanese Pound',
        countryCode: 'LB',
    },
    LKR: {
        name: 'Sri Lankan Rupee',
        countryCode: 'LK',
    },
    LRD: {
        name: 'Liberian Dollar',
        countryCode: 'LR',
    },
    LSL: {
        name: 'Lesotho Loti',
        countryCode: 'LS',
    },
    LTC: {
        name: 'Litecoin',
        countryCode: 'US',
    },
    LTL: {
        name: 'Lithuanian Litas',
        countryCode: 'LT',
    },
    LVL: {
        name: 'Latvian Lats',
        countryCode: 'LV',
    },
    LYD: {
        name: 'Libyan Dinar',
        countryCode: 'LY',
    },
    MAD: {
        name: 'Moroccan Dirham',
        countryCode: 'MA',
    },
    MATIC: {
        name: 'Polygon',
        countryCode: 'US',
    },
    MDL: {
        name: 'Moldovan Leu',
        countryCode: 'MD',
    },
    MGA: {
        name: 'Malagasy Ariary',
        countryCode: 'MG',
    },
    MKD: {
        name: 'Macedonian Denar',
        countryCode: 'MK',
    },
    MMK: {
        name: 'Myanmar Kyat',
        countryCode: 'MM',
    },
    MNT: {
        name: 'Mongolian Tugrik',
        countryCode: 'MN',
    },
    MOP: {
        name: 'Macanese Pataca',
        countryCode: 'MO',
    },
    MRO: {
        name: 'Mauritanian Ouguiya',
        countryCode: 'MR',
    },
    MUR: {
        name: 'Mauritian Rupee',
        countryCode: 'MU',
    },
    MVR: {
        name: 'Maldivian Rufiyaa',
        countryCode: 'MV',
    },
    MWK: {
        name: 'Malawian Kwacha',
        countryCode: 'MW',
    },
    MXN: {
        name: 'Mexican Peso',
        countryCode: 'MX',
    },
    MYR: {
        name: 'Malaysian Ringgit',
        countryCode: 'MY',
    },
    MZN: {
        name: 'Mozambican Metical',
        countryCode: 'MZ',
    },
    NAD: {
        name: 'Namibian Dollar',
        countryCode: 'NA',
    },
    NGN: {
        name: 'Nigerian Naira',
        countryCode: 'NG',
    },
    NIO: {
        name: 'Nicaraguan Córdoba',
        countryCode: 'NI',
    },
    NOK: {
        name: 'Norwegian Krone',
        countryCode: 'NO',
    },
    NPR: {
        name: 'Nepalese Rupee',
        countryCode: 'NP',
    },
    NZD: {
        name: 'New Zealand Dollar',
        countryCode: 'NZ',
    },
    OMR: {
        name: 'Omani Rial',
        countryCode: 'OM',
    },
    OP: {
        name: 'Opacity',
        countryCode: 'US',
    },
    PAB: {
        name: 'Panamanian Balboa',
        countryCode: 'PA',
    },
    PEN: {
        name: 'Peruvian Nuevo Sol',
        countryCode: 'PE',
    },
    PGK: {
        name: 'Papua New Guinean Kina',
        countryCode: 'PG',
    },
    PHP: {
        name: 'Philippine Peso',
        countryCode: 'PH',
    },
    PKR: {
        name: 'Pakistani Rupee',
        countryCode: 'PK',
    },
    PLN: {
        name: 'Polish Złoty',
        countryCode: 'PL',
    },
    PYG: {
        name: 'Paraguayan Guarani',
        countryCode: 'PY',
    },
    QAR: {
        name: 'Qatari Rial',
        countryCode: 'QA',
    },
    RON: {
        name: 'Romanian Leu',
        countryCode: 'RO',
    },
    RSD: {
        name: 'Serbian Dinar',
        countryCode: 'RS',
    },
    RUB: {
        name: 'Russian Ruble',
        countryCode: 'RU',
    },
    RWF: {
        name: 'Rwandan Franc',
        countryCode: 'RW',
    },
    SAR: {
        name: 'Saudi Riyal',
        countryCode: 'SA',
    },
    SBD: {
        name: 'Solomon Islands Dollar',
        countryCode: 'SB',
    },
    SCR: {
        name: 'Seychellois Rupee',
        countryCode: 'SC',
    },
    SDG: {
        name: 'Sudanese Pound',
        countryCode: 'SD',
    },
    SEK: {
        name: 'Swedish Krona',
        countryCode: 'SE',
    },
    SGD: {
        name: 'Singapore Dollar',
        countryCode: 'SG',
    },
    SHP: {
        name: 'Saint Helena Pound',
        countryCode: 'SH',
    },
    SLL: {
        name: 'Sierra Leonean Leone',
        countryCode: 'SL',
    },
    SOL: {
        name: 'Solana',
        countryCode: 'US',
    },
    SOS: {
        name: 'Somali Shilling',
        countryCode: 'SO',
    },
    SRD: {
        name: 'Surinamese Dollar',
        countryCode: 'SR',
    },
    STD: {
        name: 'São Tomé and Príncipe Dobra',
        countryCode: 'ST',
    },
    SVC: {
        name: 'Salvadoran Colón',
        countryCode: 'SV',
    },
    SYP: {
        name: 'Syrian Pound',
        countryCode: 'SY',
    },
    SZL: {
        name: 'Swazi Lilangeni',
        countryCode: 'SZ',
    },
    THB: {
        name: 'Thai Baht',
        countryCode: 'TH',
    },
    TJS: {
        name: 'Tajikistani Somoni',
        countryCode: 'TJ',
    },
    TMT: {
        name: 'Turkmenistan Manat',
        countryCode: 'TM',
    },
    TND: {
        name: 'Tunisian Dinar',
        countryCode: 'TN',
    },
    TOP: {
        name: "Tongan Pa'anga",
        countryCode: 'TO',
    },
    TRY: {
        name: 'Turkish Lira',
        countryCode: 'TR',
    },
    TTD: {
        name: 'Trinidad and Tobago Dollar',
        countryCode: 'TT',
    },
    TWD: {
        name: 'New Taiwan Dollar',
        countryCode: 'TW',
    },
    TZS: {
        name: 'Tanzanian Shilling',
        countryCode: 'TZ',
    },
    UAH: {
        name: 'Ukrainian Hryvnia',
        countryCode: 'UA',
    },
    UGX: {
        name: 'Ugandan Shilling',
        countryCode: 'UG',
    },
    USD: {
        name: 'United States Dollar',
        countryCode: 'US',
    },
    USDC: {
        name: 'USD Coin',
        countryCode: 'US',
    },
    USDT: {
        name: 'Tether',
        countryCode: 'US',
    },
    UYU: {
        name: 'Uruguayan Peso',
        countryCode: 'UY',
    },
    UZS: {
        name: 'Uzbekistan Som',
        countryCode: 'UZ',
    },
    VEF: {
        name: 'Venezuelan Bolívar',
        countryCode: 'VE',
    },
    VND: {
        name: 'Vietnamese Đồng',
        countryCode: 'VN',
    },
    VUV: {
        name: 'Vanuatu Vatu',
        countryCode: 'VU',
    },
    WST: {
        name: 'Samoan Tala',
        countryCode: 'WS',
    },
    XAF: {
        name: 'Central African CFA Franc',
        countryCode: 'CF',
    },
    XAG: {
        name: 'Silver Ounce',
        countryCode: 'ZZ',
    },
    XAU: {
        name: 'Gold Ounce',
        countryCode: 'ZZ',
    },
    XCD: {
        name: 'East Caribbean Dollar',
        countryCode: 'AG',
    },
    XDR: {
        name: 'Special Drawing Rights',
        countryCode: 'ZZ',
    },
    XOF: {
        name: 'West African CFA Franc',
        countryCode: 'BJ',
    },
    XPD: {
        name: 'Palladium Ounce',
        countryCode: 'ZZ',
    },
    XPF: {
        name: 'CFP Franc',
        countryCode: 'PF',
    },
    XPT: {
        name: 'Platinum Ounce',
        countryCode: 'ZZ',
    },
    XRP: {
        name: 'Ripple',
        countryCode: 'US',
    },
    YER: {
        name: 'Yemeni Rial',
        countryCode: 'YE',
    },
    ZAR: {
        name: 'South African Rand',
        countryCode: 'ZA',
    },
    ZMK: {
        name: 'Zambian Kwacha (pre-2013)',
        countryCode: 'ZM',
    },
    ZMW: {
        name: 'Zambian Kwacha',
        countryCode: 'ZM',
    },
    ZWL: {
        name: 'Zimbabwean Dollar',
        countryCode: 'ZW',
    },
}
