import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMessage,
    faEnvelope,
    faPhone,
    faSignature,
} from '@fortawesome/free-solid-svg-icons'
import {
    getAddUsInYourWillText,
    getDonateToUsInKindText,
    getHomeworkText,
    getTransportText,
    getMatrickerDanceText,
    getBirthdayText,
    getHostAChildText,
    getBefriendAChildText,
    getFundraiseText,
    getOfficeText,
    tabNames,
} from '../tabs/tabs_data'
import pic from '../../assets/images/kids/pic8.jpg'
import pic2 from '../../assets/images/kids/pic15.jpg'
import pic3 from '../../assets/images/kids/pic4.jpg'
import pic4 from '../../assets/images/kids/pic3.jpg'
import React, { useState, useEffect } from 'react'
import { logInfo, logWarning } from '../../utilities/log'
import { ContactForm_M } from '../../models/contact_form'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { notifySuccess, notifyError } from '../../utilities/notify'
import { errorNotification, successNotification } from '../../utilities/common'

/**
 *
 *
 * @export
 * @return {*}
 */
export default function HelpContactForm({ message, closeModal, isOptions }) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()

    const tabs = [
        {
            name: '',
            index: 0,
            heading: 'Donate gifts in kind',
            text: getDonateToUsInKindText(),
            image: pic,
        },
        {
            name: '',
            index: 1,
            heading: 'Help with transport',
            text: getTransportText(),
            image: pic2,
        },
        {
            name: '',
            index: 2,
            heading: 'Help with homework',
            text: getHomeworkText(),
            image: pic3,
        },
        {
            name: '',
            index: 3,
            heading: 'Make Matric Dance magic',
            text: getMatrickerDanceText(),
            image: pic4,
        },
        {
            name: '',
            index: 4,
            heading: 'Sponsor a birthday party',
            text: getBirthdayText(),
            image: pic2,
        },
        {
            name: '',
            index: 5,
            heading: 'Host a child',
            text: getHostAChildText(),
            image: pic,
        },
        {
            name: '',
            index: 6,
            heading: 'Befriend a child',
            text: getBefriendAChildText(),
            image: pic3,
        },
        {
            name: '',
            index: 7,
            heading: 'Fundraise for us',
            text: getFundraiseText(),
            image: pic4,
        },
        {
            name: '',
            index: 8,
            heading: 'Help out in our office',
            text: getOfficeText(),
            image: pic,
        },
        {
            name: tabNames.WILL,
            index: 9,
            heading: 'Remember us in your will',
            text: getAddUsInYourWillText(),
            image: pic2,
        },
    ]

    const [formLoading, setFormLoading] = useState(false)
    const [messagee, setMessage] = useState('')

    let [formState, setFormState] = useState({
        name: '',
        lastName: '',
        email: '',
        help: '',
        mobileNumber: '',
        message: '',
    })

    useEffect(() => {
        if (message) {
            setFormState((prevState) => ({
                ...prevState,
                message: message,
            }))
        }
        return () => {}
    }, [])

    /**
     * a method used to update form state
     *
     * @param {Event} event
     */
    function onInputChange(key, event) {
        if (event.target) {
            const stateCopy = { ...formState }
            logInfo('[setFormState] stateCopy : ', stateCopy)

            logInfo(
                `[contact_form] -> onInputChange key: ${key}  value: ${event.target.value}`
            )

            setFormState((prevState) => ({
                ...prevState,
                [key]: event.target.value,
            }))
        } else {
            logWarning(`no target found for input ${key} `)
        }
    }

    function submitForm(params) {
        setTimeout(() => {
            closeModal()
        }, 2000)
    }

    /**
     * this function is called once submit button is clicked,
     * it then calls the saveUserDetails function which saves the users data to the database.
     */
    const handleContactForm = (e) => {
        e.preventDefault()
        saveUserDetails()
    }

    /**
     * this function returns the heading of the tab by refering to the index passed into the function
     */
    const getHelpDescription = (index) => {
        return tabs[index].heading
    }

    /**
     * this function submits the users contact details to the database
     */
    const saveUserDetails = handleSubmit(async (data) => {
        setFormLoading(true)

        await fetch(`${process.env.REACT_APP_API_URL}/volunteer`, {
            method: 'POST',
            body: JSON.stringify({
                email: data.email,
                first_name: data.name,
                last_name: data.lastName,
                phone_number: data.mobileNumber,
                message: formState.message,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.error) {
                    errorNotification(
                        'Oops failed to send your application. Retry'
                    )
                    return
                }
                successNotification(response?.message, 10000)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            })
            .catch((error) => {
                if (error.message) {
                    errorNotification(error.message)
                    return
                }
                errorNotification('Oops failed to send your application. Retry')
            })
            .finally(() => {
                setFormLoading(false)
                submitForm()
            })
    })

    return (
        <div className="contact-form">
            <form className="contact-form-container">
                <div className="row contact-form-row">
                    {/* name */}
                    <div className="col-md-6">
                        <div className="contact-form-material-textfield">
                            <input
                                value={formState.name}
                                {...register('name', {
                                    required: 'enter a valid name',
                                })}
                                placeholder=""
                                onChange={(e) => onInputChange('name', e)}
                            />
                            <label>name</label>
                            <span className="icon">
                                <FontAwesomeIcon icon={faSignature} />
                            </span>
                        </div>
                    </div>

                    {/* surname */}
                    <div className="col-md-6">
                        <div className="contact-form-material-textfield">
                            <input
                                value={formState.lastName}
                                placeholder=""
                                {...register('lastName', {
                                    required: 'enter a valid lastName',
                                })}
                                onChange={(e) => onInputChange('lastName', e)}
                            />
                            <label>last name</label>
                            <span className="icon">
                                <FontAwesomeIcon icon={faSignature} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row contact-form-row">
                    {/* email */}
                    <div className="col-md-12">
                        <div className="contact-form-material-textfield">
                            <input
                                value={formState.email}
                                placeholder=" "
                                {...register('email', {
                                    required: 'enter a valid email',
                                })}
                                onChange={(e) => onInputChange('email', e)}
                            />
                            <label>email address</label>
                            <span className="icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row contact-form-row">
                    {/* email */}
                    <div className="col-md-12">
                        <div className="contact-form-material-textfield">
                            {isOptions === false ? (
                                ''
                            ) : (
                                <select
                                    name="help"
                                    {...register('message', {
                                        required: 'enter a valid message',
                                    })}
                                    onChange={(e) => {
                                        setFormState({ help: e.target.value, message: e.target.value})
                                    }}
                                >
                                    <option hidden>
                                        How would you like to help?
                                    </option>
                                    <option value={tabs[0].heading}>
                                        {getHelpDescription(0)}
                                    </option>
                                    <option value={tabs[1].heading}>
                                        {getHelpDescription(1)}
                                    </option>
                                    <option value={tabs[2].heading}>
                                        {getHelpDescription(2)}
                                    </option>
                                    <option value={tabs[3].heading}>
                                        {getHelpDescription(3)}
                                    </option>
                                    <option value={tabs[4].heading}>
                                        {getHelpDescription(4)}
                                    </option>
                                    <option value={tabs[5].heading}>
                                        {getHelpDescription(5)}
                                    </option>
                                    <option value={tabs[6].heading}>
                                        {getHelpDescription(6)}
                                    </option>
                                    <option value={tabs[7].heading}>
                                        {getHelpDescription(7)}
                                    </option>
                                    <option value={tabs[8].heading}>
                                        {getHelpDescription(8)}
                                    </option>
                                    <option value={tabs[9].heading}>
                                        {getHelpDescription(9)}
                                    </option>
                                </select>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row contact-form-row">
                    {/* mobile number */}
                    <div className="col-md-12">
                        <div className="contact-form-material-textfield">
                            <input
                                value={formState.mobileNumber}
                                // type='number'
                                placeholder=""
                                {...register('mobileNumber', {
                                    required: 'enter a valid mobileNumber',
                                })}
                                onChange={(e) =>
                                    onInputChange('mobileNumber', e)
                                }
                            />
                            <label>mobile number</label>
                            <span className="icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row contact-form-row">
                    <div className="col-md-12">
                        <div className="contact-form-material-textfield contact-form-textarea">
                            <textarea
                                value={formState.message}
                                rows="6"
                                placeholder=" "
                                onChange={(e) => onInputChange('message', e)}
                               
                            >
                                {' '}
                            </textarea>
                            <label>message</label>
                            <span className="icon">
                                <FontAwesomeIcon icon={faMessage} />
                            </span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button
                            className="contact-form-button"
                            onClick={(e) => handleContactForm(e)}
                        >
                            {formLoading ? 'Processing' : 'Submit'}
                            <span
                                className={
                                    'spinner ' +
                                    (formLoading ? ' spinner-active' : '')
                                }
                            ></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
