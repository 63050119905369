import ContactModal from '../contact_modal/contact_modal'

export const tabNames = {
  WILL: 'will'
}


export function getAddUsInYourWillText() {

  return (
    <div className='will-container'>
      {/* <ContactModal /> */}
      <h2 className="will-heading">The benefits of creating a will</h2>

      <p>
        Naturally, you will first want to make provision for your spouse and children,
        if any.  But in cases where the children are grown up and successful in their own
        right, it can be extremely satisfying to extend your generosity to others who lack
        the opportunities your own children enjoyed.
      </p>
      
      <p>
        If you die  intestate  without a will  and have no
        surviving relatives, everything you  possess may eventually revert to the State.
        A will is one of the most important documents in your life
        A will  provides peace of mind and security, because you know that your money,
        possessions and assets will be distributed according to your wishes.&nbsp; 
        What’s more, it allows you to continue  supporting causes that are important to you. 
        It is important to get professional advice when making a will
      </p>
      <p>
        There are  numerous legal requirements that must be adhered to, so as to ensure the  
        validity of your will.&nbsp; It will also  ensure clarity when your instructions are
        carried out. The laws  pertaining to Estates are revised from time to time.&nbsp; 
        It is to your advantage to consult a  professional in order to reduce the tax 
        liability of your estate.&nbsp; For many, creating a tax-efficient will  provides
        an opportunity to direct estate duty savings to a charity or  organisation of their 
        choice.Don’t deny yourself the  opportunity to have a final say about the things 
        and people who matter to you most.
      </p>
        
      <h2 className="will-heading-2">What is a Bequest ?</h2>

      <p>A bequest,  or legacy as it is known in some countries, is a gift written into a will.&nbsp; It can be a set amount and/or specified  item(s) such as real estate, shares, or the proceeds of a life insurance  policy.&nbsp; You could also elect to bequeath  a percentage of your estate or the residue of your estate, that is, the amount  remaining after all costs and other bequests have been settled, or the total  estate after testamentary expenses.</p>
      <p>A bequest  is a promise for the future, meaning that the giver can remain interested and  involved while being assured that their present income will not decrease,  because their gift is made from assets, not current income.</p>
      <h2 className="will-heading-2" >How can i give a gift to St Mary's in my will ?</h2>
      <p>Naturally,  you will first want to make provision for your spouse and children, if  any.&nbsp; But in cases where the children are  grown up and successful in their own right, it can be extremely satisfying to  extend your generosity to others who lack the opportunities your own children  enjoyed.</p>
 
      <p>Contact us if you would like more information about making a bequest to St Mary’s. What a special way to be remembered and to have an impact even after death.</p>


      {/* <button className="will-button">Contact Us</button> */}
    </div>
  )
}

export function getDonateToUsInKindText() {
  return (
    <>
      <h3>Gifts in kind </h3>
      <br />

      <p>
          There's almost nothing we can't use! Soap, shampoo, toilet rolls, tissues, deodorant .... fresh fruit, milk and other food items ... bed linen, towels, rugs ... paint, light bulbs, cleaning products, second-hand furniture and/or appliances ... in fact all the things you need to run your own home, only in larger quantities!
          Gifts in kind may be dropped off at the Home every day from 9am-5pm, or tel. 011 435 3820 to arrange collection.
      </p>
      
    </>
  );
}

export function getTransportText(params) {
  return (
    <>
      <h3>Transport</h3>
      <br />

      <p>
          With only two buses between all the children and 14 schools to drop them off at,
          making sure each one is able to get to extra murals, lessons, doctor's appointments, 
          therapeutic appointments and friends' homes is a nightmare!
          If you have a car, a valid driver's license and some spare time in the afternoon, 
          you'll be welcomed with open arms!
      </p>
    </>);
}

export function getHomeworkText(params) {
  return (
    <>
      <h3>Homework</h3>
      <br />

      <p>Everything from listening to a Class 1 child's reading, to helping with projects and 'testing' to see if  children are well prepared for tomorrow's lessons is appreciated. With 52  children sitting down to tackle homework every afternoon, the more willing ears  and eyes, the better! Or if you are skilled in a particular sports, our  children could benefit from your coaching. Extra tuition is always essential  before our children write exams.</p>
    </>
  );
}

export function getMatrickerDanceText() {
  return (<>
    <h3>Matric Farewells</h3>
    <br />

    <p>
      Making Matric Farewells a memorable occasions is especially important to us, 
      as our children have often been looking down upon on civvies-days etcetera.  
      There are many opportunities to assist such as sewing/buying a formal dress, 
      helping with hair and make-up and transport or taking photographs.  
      Things you would do for your own son or daughter to make them feel extra special!
      Our children deserves that too. 
    </p>
  </>)
}

export function getBirthdayText(params) {
  return (
    <>
      <h1>Birthdays </h1>
      <br />

      <p>At St Mary’s every child’s birthday is a special celebration  of their life.&nbsp; Birthdays are celebrated  individually, regardless of how many children celebrate their birthdays in one  month.&nbsp; St Mary’s feels it is important  for their children to know that despite living in a children’s home, or not  having any family left, that they, as individuals are special, loved and  counted as one of our precious children.</p>
    </>
  );
}

export function getHostAChildText() {
  return (
    <>
      <h1>Hosting a child</h1>
      <br />

      <p>
        Special procedures are in place when you want to host a child for a weekend/holiday and 
        even for more permanent options. It is a very important function as it will be their only
        way to observe a “healthy” family environment. This act of kindness can make a big difference
        in a child’s development as well as sense of hope for a brighter and better future.
      </p>
    </>
  );
}

export function getBefriendAChildText() {
  return (
    <>
      <h1>Befriending a St Mary's child </h1>
      <br />

      <p>
        Can range from the
        simplest action, like phoning on their birthday or sending a card,
        to attending
        a school concert or sports day, or taking two or three girls to the
        mall to shop. In other words, interacting
        with the child like a big brother or sister, auntie or uncle.
      </p>
    </>
  );
}

export function getFundraiseText() {
  return (
    <>
      <h1>Fundraising</h1>
      <br />

      <p>
        Set your imagination free!
        Run a marathon and get your friends to sponsor you ... hold a dinner
        party and charge your guests an entrance fee ... hold a jumble sale
        and donate the proceeds to St Mary's ... make a collection for us
        at your church ... celebrate your birthday or anniversary by asking
        friends to donate to us in lieu of a gift. 
      </p>
      
      <p>
        In addition, please act on our behalf in referring us to companies with
        a social responsibility program and/or a smaller business wanting to reach
        out to the Home on special days, such as Mandela Day. Organising a big event
        as a team-building effort amongst personnel will be very beneficial and even
        competitive with different departments and most beneficial to St Mary’s children.
      </p>
    </>
  );
}

export function getOfficeText() {
  return (
    <>
      <h1>Administrative work </h1>
      <br />
      <p>
        If you are computer literate or have book-keeping skills, 
        your talents could be put to use helping out at our office for an hour or so a week.
        Please contact us on 011 435 3820. Helping to sort and note second-hand clothing or
        acting as a volunteer through the selling of tickets for events will be an extension
        of administrative work at St Mary’s Children’s Home. 
      </p>
    </>
  );
}