class EventManger {
    OPEN_MODAL = 'openModal'
    OPEN_PAYMENT_MODAL = 'openPaymentModal'

    events = {}

    /**
     * a method used to subscribe to an event. Pass in the
     * callbackId to insure that you can unsubscribe using
     * this very same callbackId at a later stage.
     *
     * @param {*} eventType
     * @param {*} callbackId
     * @param {*} callback
     * @memberof EventManger
     */
    subscribe(eventType, callbackId, callback) {
        if (this.events[eventType]) {
            this.events[eventType].push({ callbackId, callback })
        } else {
            this.events[eventType] = [{ callbackId, callback }]
        }
    }

    /**
     * a publish an event to all listiner
     *
     * @param {*} eventType
     * @param {*} data
     * @memberof EventManger
     */
    publish(eventType, data) {
        if (this.events[eventType]) {
            for (let eventObj of this.events[eventType]) {
                eventObj.callback(data)
            }
        }
    }

    /**
     * a method used to unsubscribe a callback method from a specific
     * event , which should usually be performed just before unmounting
     * a component
     *
     * @param {*} eventType
     * @param {*} callbackId
     * @memberof EventManger
     */
    unsubscribe(eventType, callbackId) {
        if (this.events[eventType]) {
            const callbackDataForEventType = this.events[eventType]

            // remove the callback that matches the callbackId that was passed in.
            this.events[eventType] = callbackDataForEventType.filter(
                (eventObj) => {
                    return eventObj.callbackId !== callbackId
                }
            )
        }
    }
}

export default new EventManger()
